import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"300","light":""},scopedSlots:_vm._u([(_vm.icon)?{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","x-small":"","depressed":"","color":"primary"}},Object.assign({}, dialog, tooltip)),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Designation")])])]}}:{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v("Add Designation")])]}}],null,true),model:{value:(_vm.showDesignationDialog),callback:function ($$v) {_vm.showDesignationDialog=$$v},expression:"showDesignationDialog"}},[_c(VCard,[_c(VForm,{ref:"designationForm",on:{"submit":function($event){$event.preventDefault();return _vm.saveDesignation.apply(null, arguments)}}},[_c(VCardTitle,[_vm._v(" Designation Name "),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","icon":"","color":"error"},on:{"click":_vm.closeDesinationDialog}},[_vm._v(" X ")])],1),_c(VDivider),_c(VCardText,[_c(VTextField,{attrs:{"dense":"","outlined":"","autocomplete":"off","rules":[function () { return !!_vm.designation.name || 'Enter designation name'; }]},model:{value:(_vm.designation.name),callback:function ($$v) {_vm.$set(_vm.designation, "name", $$v)},expression:"designation.name"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","height":"32","dark":"","type":"submit","loading":_vm.loading}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }